<template>
	<tr>	
		<Cell col="Account" inputFormat="text" :cell="row.account" :rowNum="row.rowNum" :currentTier="row.currentTiering" :currentBand="row.currentBand" :futureBand="row.futureBand" :futureTier="row.futureTiering" />
		<Cell v-for="(cell,index) in row.cols" :key="cell.id" inputFormat="number" :col="index" :cell="cell" :rowNum="row.rowNum" />
	</tr>
</template>

<script>
	import Cell from '@/components/Cell.vue';
	import { mapState } from 'vuex';

	export default {
		name: "Row",
		props: ["row","headers"],
		components: {
			Cell
		},
		data() {
			return {
				rowData: this.row
			}
		},
		computed: {
			...mapState(['tiering'])
		},
		methods:{
			
		}
	}
	
</script>

<style lang="scss" scoped>
	
	

</style>