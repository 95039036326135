<template>
	<router-view />

	<div class="footer">
		<div class="container-fluid flex items-end">
			<div class="copyright flex-1">&copy; Copyright Calor 2022</div>
			<div class="freestyle">Powered by <img src="@/assets/fs-logo.svg"></div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'App',
	components: {
		
	},
	created() {
		//this.$store.dispatch('loadByCells');
		this.$store.dispatch('loadSheet');
		this.$store.dispatch('loadMeta');
	}
}

</script>

<style lang="scss">
html,body {
	height: 100%;
}

#app {
	background-color: #F8F8F8;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100%;
}

.footer {
	padding: 40px 0;
	font-size: 13px;
	line-height: 1;

	
	.freestyle {

		img {
			display: inline-block;
			vertical-align: bottom;
			max-width: 40px;
			margin-left: 10px;
		}
	}
}

</style>
