import { createStore } from 'vuex';
import axios from 'axios';
const { GoogleSpreadsheet } = require('google-spreadsheet');

export default createStore({
	//strict: process.env.NODE_ENV !== "production",
	state() {
		return {
			doc: {},
			sheet: {},
			docID: '1-31f2NU68qy8YhzCWJ_JVCP6fAfn3BcHKoGx2R1G8h0',
			//docID: '1NHkDPLMB9aBcTWCE_xIE40GwmXZorkmCijv5PovI0nc',
			sheetID: 97220643,
			creds: require('@/client_secret.json'),
			current: {},
			future: {},
			general: {},
			currentUnfiltered: {},
			futureUnfiltered: {},
			filter: null,
			sort: null,
			view: 'current',
			lastRowNum: 0,
			newAdded: false,
			metaData: [],
			title: '',
			siteloading: true,
			sheetloading: false
		}
	},
	mutations: {
		filterByMarketSector(state, data) {
			state.filter = data;
				
			if(state.filter) {
				console.log(state.filter)
				let newArr = state[state.view + 'Unfiltered'].filter(function(value) {
					return value.cols['Market Sector'] == state.filter;
				});
				state[state.view].rows = newArr;
			}
		},
		resetFilter(state) {
			state.filter = null;
			state.sort = null;
			state.current.rows = state.currentUnfiltered;
			state.future.rows = state.futureUnfiltered;

			state.current.rows.sort(function(a,b) {
				return b['currentTiering'] - a['currentTiering'];
			});
			state.future.rows.sort(function(a,b) {
				return b['futureTiering'] - a['futureTiering'];
			});

			console.log('reset filter');
		},
		setView(state, data) {
			if(data) {
				if(data != state.view) {
					//reset
					this.commit("resetFilter");
				}
				state.view = data;
				console.log('set view');
			}
			
		},
		sortByHeader(state, data) {
			state.sort = data.header;
			console.log('sort by header');
			
			if(!data.invert) {
				//sort normal

				switch(data.header.toLowerCase()) {
					case 'account' :
						console.log('account');
						state[state.view].rows.sort((a, b) => {
							if(a.cols[data.header] > b.cols[data.header]) return -1;
							if(a.cols[data.header] < b.cols[data.header]) return 1;
							return 0;
						});
					break;
					case 'am current effort' : {
						console.log('am current');
						let score = {
							"": 0,
							low: 1,
							medium: 2,
							high: 3,
						};
						state[state.view].rows.sort((a, b) => {
							console.log(score[a.cols[data.header].toLowerCase()])
							score[a.cols[data.header].toLowerCase()] - score[b.cols[data.header].toLowerCase()];
						});
						}
					break;
					default :
						state[state.view].rows.sort((a, b) => {
							if(a.cols[data.header] < b.cols[data.header]) return -1;
							if(a.cols[data.header] > b.cols[data.header]) return 1;
							return 0;
						});
				}
				
			} else {
				//invert sort
				state[state.view].rows.sort((a, b) => {
					if(data.header.toLowerCase() == 'account') {
						if(a.account > b.account) return -1;
						if(a.account < b.account) return 1;
					} else {
						if(a.cols[data.header] > b.cols[data.header]) return -1;
						if(a.cols[data.header] < b.cols[data.header]) return 1;
					}
					return 0;
				});
			}	
		}
	},
	actions: {
		async loadByCells({commit, state}) {
			const doc = new GoogleSpreadsheet(state.docID);
			await doc.useServiceAccountAuth(state.creds);
			await doc.loadInfo(); 
			const cellsSheet = doc.sheetsById[state.sheetID];			
			console.log('load by cells');
		},
		async loadSheet({commit, state}) {
			console.log('loadSheet');
			state.sheetloading = true;
			//Get total sheet using framework
			state.doc = new GoogleSpreadsheet(state.docID);
			await state.doc.useServiceAccountAuth(state.creds);
			await state.doc.loadInfo(); 
			
			state.originalSheet = state.doc.sheetsById[state.sheetID];			
			await state.originalSheet.loadHeaderRow(3);
			state.title = state.originalSheet.title;
			state.originalRows = await state.originalSheet.getRows({
				offset: 1
			});

			state.current = {
				headers: [],
				rows: []
			};
			state.general = {
				headers: [],
				rows: []
			};
			state.future = {
				headers: [],
				rows: []
			};
			
			//go through and split up current and future headers data, whilst removing 'tiers'
			let currentView = 'current';
			state.originalSheet.headerValues.forEach((header, index) => {
				const _header = header.toLowerCase();

				if(!_header.includes('-') && _header.length > 0) {
					if(_header == 'current tiering') currentView = 'general';
					if(_header == 'general info end') currentView = 'future';

					if(!/tiering|band|account|info end/.test(_header)) {
						switch(currentView) {
							case 'current' :
								state.current.headers.push(header);
							break;
							case 'general' :
								state.general.headers.push(header);
							break;
							case 'future' :
								state.future.headers.push(header);
							break;
						}
					}
				}
			});
			
			// now go through all rows, and build up row data from new headers, whilst adding in tiers and row sheet numbers
			state.originalRows.forEach((row, index) => {
				if(row['Account'] == "") return;
				
				let newRow = {
					cols:{},
					rowNum:row._rowNumber,
					account: row['Account']
				}

				//Current
				state.current.headers.forEach(header => {
					newRow.cols[header] = row[header];
				});
				newRow.currentTiering = row['Current Tiering'];
				newRow.futureTiering = row['Projected Tiering'];
				newRow.currentBand = row['current band'];
				newRow.futureBand = row['future band'];
				state.current.rows.push(newRow);
			
				//General
				newRow = {cols:{}, rowNum:row._rowNumber, account: row['Account']}
				state.general.headers.forEach(header => {
					newRow.cols[header] = row[header];
				});
				state.general.rows.push(newRow);

				//Future
				newRow = {cols:{}, rowNum:row._rowNumber, account: row['Account']}
				state.future.headers.forEach(header => {
					newRow.cols[header] = row[header];
				});
				newRow.currentTiering = row['Current Tiering'];
				newRow.futureTiering = row['Projected Tiering'];
				newRow.currentBand = row['current band'];
				newRow.futureBand = row['future band'];
				state.future.rows.push(newRow);
			});

			//sort rows by tiering
			state.current.rows.sort(function(a,b) {
				return b.currentTiering - a.currentTiering;
			});
			state.future.rows.sort(function(a,b) {
				return b.futureTiering - a.futureTiering;
			});
			
			state.currentUnfiltered = [...state.current.rows];
			state.futureUnfiltered = [...state.future.rows];
			state.lastRowNum = state.originalRows[state.originalRows.length-1]._rowNumber;
			state.siteloading = false;
			state.sheetloading = false;
		},
		async loadMeta({commit, state}) {
			console.log('load meta');
			const doc = new GoogleSpreadsheet(state.docID);
			await doc.useServiceAccountAuth(state.creds);
			await doc.loadInfo(); 

			const meta = doc.sheetsById[118007877];
			await meta.loadHeaderRow(3);
			const metaRows = await meta.getRows();
			
			//extract out columns into data arrays
			const metaData = {};
			metaRows[0]._sheet.headerValues.forEach((category, index) => {
				//don't want first column
				if(index > 0 && !category.includes('-') && category != '') {
					const headerValues = {desc:'', values:[]};
					metaRows.forEach((row, index) => {
						if(index == 0) headerValues.desc = row[category];
						if(index > 0 && row[category] !== "" && row[category]) {
							headerValues.values.push(row[category]);
						}
					});
					metaData[category] = headerValues;
				}
			})

			//state.metaRows = metaRows;
			state.metaData = metaData;
		},
		saveSheet({commit, state}) {
			state.sheet.save();
		}
		
	},
	getters: {
		getSectors: (state) => {
			
		},
		getTop3: (state) => {
			
		},
		getScores: (state) => {
			

			
		}
		
	},
});
