<template>
	<div class="wrapper mb-8">	
	
	<AddRow :openNew="openNew" @closeNew="closeNew()" />

	<div class="header pt-4 border-b">
		<div class="flex relative items-end container-fluid pb-4">
			<div class="flex-1">
				<img src="@/assets/calor-logo.png" class="logo inline-block mr-10">
				<h4 class="mb-0 inline-block align-bottom">Account classification</h4>
			</div>
			<div class="viewToggle">
				<router-link to="/current" class="active"><span>Current</span></router-link>
				<router-link to="/future"><span>Future</span></router-link>
				<router-link to="/general"><span>General</span></router-link>
			</div>
			<div class="btn-toolbar">
				<div class="flex items-center">
					<div @click="filterActive = !filterActive" class="button button--secondary mr-3"><span><img src="@/assets/filter-icon.svg" alt=""></span><span>Filter</span></div>
					<div @click="newRow()" class="button mr-3"><span>+</span><span>Add row</span></div>
					<router-link to="/report" class="button button--secondary mr-3"><span>+</span><span>Report</span></router-link>
                              <!-- <a href="https://datastudio.google.com/s/kWLfe3HxKVQ" target="_blank" class="button button--secondary mr-3">View results</a> -->
				</div>
			</div>
		</div>
	</div>

	<div v-if="filterActive" class="container-fluid">
		<div class="filter mt-4 flex items-center">
			<h3 class="mr-4 font-bold">Market sector</h3>
			<select @change="filterResults($event)">
				<option value="">Please select</option>
				<option v-for="sector in metaData['Market Sector'].values" :key="sector.id" :value="sector">{{sector}}</option>
			</select>
		</div>
	</div>

	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import AddRow from '@/components/AddRow';

	export default {
		name: "Header",
		components: {
			AddRow
		},
		props: ["header"],
		data() {
			return {
				openNew: false,
				filterActive: null
			}
		},
		computed: {
			...mapState(['metaData', 'currentView'])
		},
		methods: {
			filterResults(event) {
				if(event.target.value) {
					this.$store.commit("filterByMarketSector", event.target.value);
				} else {
					this.$store.commit('resetFilter');
				}
			},
			newRow() {
				this.openNew = true;
			},
			closeNew() {
				this.openNew = false;
			},
			checkLogin(state) {
				if(state != 'Login') {
					//check login state						
					if(!this.$cookies.isKey('auth')) {
						this.$router.push({
							name: "Login"
						});
					}
				}
			}
		},
		mounted() {
			this.checkLogin(this.$route.name)
		},
		watch: {
			currentView: function(val) {
				if(!val) {
					this.filterActive = false;
				}
			},
			filterActive: function(val) {
				this.$store.commit('resetFilter');
			},
			$route: {
				deep: true,
				handler(to, from) {
					this.checkLogin(to.name);
				},
			},
		}
	}
	
</script>

<style lang="scss" scoped>
	.header {
		background: white;
	}
	.logo {
		max-width: 130px;
	}
	.viewToggle {
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		line-height: 1;
		font-size: 15px;

		a {
			font-weight:bold;
			padding: 0 30px;
			display: inline-block;
			opacity: 0.4;

			span {
				display: block;
				margin-bottom: 20px;
			}

			&.router-link-active {
				opacity: 1;
				border-bottom: 3px solid $calorRed;
			}
		}
	}

	.filter {
		background: #FFFFFF;
		border: 1px solid #C7C7C7;
		border-radius: 10px;
		padding: 15px 20px;

		select {
			border: 1px solid #ccc;
			width: auto;
		}
	}
</style>